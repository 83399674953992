var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"AhEBJa7edUSZAZeuwz9z0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ERROR_SAMPLE_RATE =
  process.env.SENTRY_ERROR_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_ERROR_SAMPLE_RATE || 0.0
const SENTRY_TRACES_SAMPLE_RATE =
  process.env.SENTRY_TRACES_SAMPLE_RATE || process.env.NEXT_PUBLIC_SENTRY_TRACES_SAMPLE_RATE || 0.0

Sentry.init({
  dsn: SENTRY_DSN,
  sampleRate: SENTRY_ERROR_SAMPLE_RATE,
  tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps

  // Called for message and error events
  beforeSend(event) {
    const errorsToFilter = ['ResizeObserver loop limit exceeded']
    const errorMessage = event?.message ?? ''

    if (errorsToFilter.includes(errorMessage)) {
      return null
    }

    return event
  }
})
