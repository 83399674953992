import SentenceEnumerationClearIcon from 'components/design-system/bits/sentence-enumeration-clear-icon'
import SentenceEnumerationIcon from 'components/design-system/bits/sentence-enumeration-icon'
import { Control } from './Control'

const isEnumerationOperation = (operation) => operation?.attributes?.enumeration === true

const clearSenteceEnumeration = (quill: any) => {
  const contents = quill.getContents()
  let deltaOperations = contents.ops

  if (deltaOperations.length > 0) {
    deltaOperations = deltaOperations.filter((operation) => !isEnumerationOperation(operation))
    quill.setContents(deltaOperations)
  }
}

const enumerateSentences = (quill: any) => {
  clearSenteceEnumeration(quill)

  const contents = quill.getContents()
  const deltaOperations = contents.ops

  if (deltaOperations.length > 0) {
    let enumeratorIndex = 1
    let operationIndex = 0

    while (operationIndex < deltaOperations.length) {
      const currentOperation = deltaOperations[operationIndex]
      const currentText = currentOperation.insert

      if (typeof currentText === 'string') {
        // Detect a sentence start (First uppercase letter)
        const sentenceStartIndex = currentText.search(/[A-Z]/)

        if (sentenceStartIndex >= 0) {
          // Look for the sentece end in this or one of the following delta operations
          for (
            let lookupOperationIndex = operationIndex;
            lookupOperationIndex < deltaOperations.length;
            lookupOperationIndex++
          ) {
            const lookupOperation = deltaOperations[lookupOperationIndex]
            const lookupText = lookupOperation.insert
            let sentenceEndIndex

            // If the lookup operation is the current one then search for the sentence end *after* the beginnning of the sentence (first uppercase letter)
            if (lookupOperationIndex == operationIndex) {
              const lookupSubText = lookupText.substring(sentenceStartIndex)
              const subSentenceEndIndex = lookupSubText.search(/[.!?\n]/)

              sentenceEndIndex = subSentenceEndIndex >= 0 ? subSentenceEndIndex + sentenceStartIndex : -1
            } else {
              sentenceEndIndex = lookupText.search(/[.!?\n]/)
            }

            // Detect a sentence end
            if (sentenceEndIndex >= 0) {
              // Split the operation that has the sentence end to start a new sentence after the sentence end
              deltaOperations.splice(
                lookupOperationIndex,
                1,
                {
                  ...lookupOperation,
                  insert: lookupText.substring(0, sentenceEndIndex + 1)
                },
                {
                  ...lookupOperation,
                  insert: lookupText.substring(sentenceEndIndex + 1)
                }
              )

              // Detect sentence finished in the right way (With .!?).
              if (lookupText[sentenceEndIndex] != '\n') {
                const newCurrentText = deltaOperations[operationIndex].insert

                deltaOperations.splice(
                  operationIndex,
                  1,
                  {
                    ...currentOperation,
                    insert: newCurrentText.substring(0, sentenceStartIndex)
                  },
                  {
                    insert: `${enumeratorIndex++}`,
                    attributes: { enumeration: true }
                  },
                  {
                    ...currentOperation,
                    insert: newCurrentText.substring(sentenceStartIndex)
                  }
                )

                operationIndex = lookupOperationIndex + 2
              } else {
                operationIndex = lookupOperationIndex
              }

              // Sentence end found so break the lookup
              break
            }
          }
        }
      }

      operationIndex++
    }

    quill.setContents(deltaOperations)
  }
}

export const SentenceEnumerator: Control = {
  id: 'sentenceEnumerator',
  formats: ['enumeration'],
  tooltip: 'Enumerate sentences',
  icon: <SentenceEnumerationIcon />,
  onClick: enumerateSentences
}

export const SentenceEnumeratorClear: Control = {
  id: 'sentenceEnumeratorClear',
  formats: ['enumeration'],
  tooltip: 'Clear sentence enumerations',
  icon: <SentenceEnumerationClearIcon />,
  onClick: clearSenteceEnumeration
}
